import { type ReactNode, useRef, useState } from 'react'
import { cx } from 'utils/cx'

import styles from './Content.module.scss'

export function Content({
	children,
	className = '',
	'data-testid': dataTestId,
	hasPadding = true,
}: Readonly<{
	children: ReactNode
	className?: string
	'data-testid'?: string
	hasPadding?: boolean
}>) {
	const [scrolling, setScrolling] = useState(false)
	const contentRef = useRef<HTMLElement | null>(null)

	return (
		<main
			data-type='content'
			data-scrolling={scrolling ? 'true' : undefined}
			onScroll={(e) => {
				if ((contentRef.current?.scrollTop ?? 0) > 0) {
					setScrolling(true)
				}
				e.preventDefault()
			}}
			onTouchEnd={() => {
				if (scrolling && (contentRef.current?.scrollTop ?? 0) === 0) {
					setTimeout(() => {
						setScrolling(false)
					})
				}
			}}
			ref={contentRef}
			data-testid={dataTestId}
			className={cx(styles.content, !hasPadding && styles.noPadding, className)}
		>
			{children}
		</main>
	)
}
