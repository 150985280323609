'use client'

import { useLayoutLayers } from 'hooks/useLayoutLayers/useLayoutLayers'
import {
	type Dispatch,
	type SetStateAction,
	forwardRef,
	useCallback,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from 'react'
import { Keyboard } from 'types/keyboard'
import { cx } from 'utils/cx'

import { createPortal } from 'react-dom'
import styles from './Dialog.module.scss'

const DIALOG = 'dialog'
const ALERT = 'alert'

interface DialogPros {
	readonly title: string
	readonly containerClassName?: string
	readonly children: React.ReactNode
	readonly preventClose?: boolean
	readonly className?: string
	readonly opened: boolean
	readonly 'data-testid'?: string
	readonly setOpened: Dispatch<SetStateAction<boolean>>
	readonly onClose?: () => void
	readonly onOpen?: () => void
	readonly nonModal?: boolean
	readonly location?: {
		top?: string
		left?: string
		right?: string
		bottom?: string
	}
}

export interface DialogAPI {
	show: () => void
	close: () => void
	open: boolean
	element: HTMLDialogElement | null
}

export function apiFactory(
	setOpened: Dispatch<SetStateAction<boolean>>,
	isOpen: boolean,
	element: HTMLDialogElement | null
): DialogAPI {
	return {
		show: () => {
			setOpened(true)
		},
		close: () => {
			setOpened(false)
		},
		open: isOpen,
		element,
	}
}

function DialogComponent(
	{
		title,
		containerClassName = '',
		children,
		onClose,
		onOpen,
		opened,
		preventClose = false,
		className = '',
		setOpened,
		location,
		nonModal = false,
		'data-testid': dataTestId,
	}: DialogPros,
	ref: React.Ref<HTMLDialogElement | null>
) {
	const [onClient, setOnClient] = useState(false)
	const dialogRef = useRef<HTMLDialogElement>(null)
	const animationRef = useRef<HTMLDivElement>(null)

	const { dialogs, application } = useLayoutLayers()

	const close = useCallback(() => {
		if (onClient) {
			setOpened(false)
		}
	}, [setOpened, onClient])

	// Exposing Dialog API to parent.
	useImperativeHandle(ref, () => (onClient ? dialogRef.current : null), [
		onClient,
	])

	const cancelHandle = useCallback(
		(e: Event) => {
			if (onClient) {
				e.preventDefault()
				e.stopPropagation()
				if (preventClose) {
					return
				}
				close()
			}
		},
		[close, preventClose, onClient]
	)

	const scapeKeyHandle = useCallback(
		(e: KeyboardEvent) => {
			if (e.code === Keyboard.Escape) {
				cancelHandle(e)
			}
		},
		[cancelHandle]
	)

	const openDialog = useCallback(() => {
		if (onClient) {
			dialogRef.current?.show()
			if (!preventClose) {
				dialogRef.current?.addEventListener('keydown', scapeKeyHandle)
			}
			if (!nonModal) {
				application?.setAttribute('inert', '')
			}
			onOpen?.()
		}
	}, [onClient, preventClose, application, nonModal, onOpen, scapeKeyHandle])

	const closeDialog = useCallback(() => {
		if (onClient) {
			onClose?.()
			if (!preventClose) {
				dialogRef.current?.removeEventListener('keydown', scapeKeyHandle)
			}
			application?.removeAttribute('inert')
			dialogRef.current?.close()
		}
	}, [onClient, onClose, preventClose, application, scapeKeyHandle])

	// Exposing Dialog API to parent.
	useImperativeHandle(ref, () => (onClient ? dialogRef.current : null), [
		onClient,
	])

	const clickHandle = useCallback(
		(e: MouseEvent) => {
			if (onClient) {
				if (e.target !== dialogRef.current || preventClose) {
					return
				}
				e.preventDefault()
				onClose?.()
				close()
			}
		},
		[close, preventClose, onClose, onClient]
	)

	useEffect(() => {
		const isElementOpened = Boolean(dialogRef.current?.open)
		if (onClient && opened && !isElementOpened) {
			openDialog()
		}
		if (onClient && !opened && isElementOpened) {
			closeDialog()
		}
	}, [closeDialog, openDialog, opened, onClient])

	useEffect(() => {
		if (onClient && opened) {
			dialogRef.current?.focus()
		}
	}, [opened, onClient])

	useEffect(() => {
		let reference: HTMLElement | null = null

		const keyDownHandle = (e: KeyboardEvent) => {
			if (e.key === Keyboard.Escape && preventClose) {
				e.preventDefault()
			}
		}

		if (onClient) {
			reference = dialogRef.current
			reference?.addEventListener('keydown', keyDownHandle)
			reference?.addEventListener('cancel', cancelHandle)
			reference?.addEventListener('click', clickHandle)
		}

		return () => {
			reference?.removeEventListener('cancel', cancelHandle)
			reference?.removeEventListener('click', clickHandle)
			reference?.removeEventListener('keydown', keyDownHandle)
		}
	}, [cancelHandle, clickHandle, preventClose, onClient])

	useEffect(() => {
		setOnClient(true)
	}, [])

	const dialogRole = preventClose ? ALERT : DIALOG

	if (!onClient) {
		return null
	}

	const dialogContent = (
		<dialog
			data-testid={dataTestId}
			className={cx(
				styles.dialog,
				className,
				nonModal ? styles.nonModal : styles.modal
			)}
			aria-label={title}
			ref={dialogRef}
			role={dialogRole}
		>
			<div
				style={location}
				data-dialog-container
				ref={animationRef}
				className={containerClassName}
			>
				{opened && children}
			</div>
		</dialog>
	)

	if (nonModal) {
		return dialogContent
	}

	return createPortal(dialogContent, dialogs ?? document.body)
}

export const Dialog = forwardRef(DialogComponent)
