'use client'

import { ButtonIconTransparent } from '@fukku/button/Icon/Transparent'
import { IconCloseBig } from 'icons/components/IconCloseBig'
import { IconMinus } from 'icons/components/IconMinus'
import { IconPrevLarge } from 'icons/components/IconPrevLarge'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import type { ReactNode } from 'react'
import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'
import { cx } from 'utils/cx'

import { DialogType, useDialogContext } from '../hooks/useDialogContext'

import fukkuStyles from 'fukku/styles/classes.module.scss'
import styles from './Header.module.scss'

export type HeaderProps = {
	readonly title?: string
	readonly subtitle?: string
	readonly onBack?: () => void
	readonly onMinus?: () => void
	readonly className?: string
	readonly 'data-testid'?: string
	readonly alternativeAction?: ReactNode
}

export function Header({
	title,
	subtitle,
	'data-testid': dataTestId,
	className = '',
	onBack = undefined,
	alternativeAction,
	onMinus = undefined,
}: Readonly<HeaderProps>) {
	const { type, api, preventClose } = useDialogContext()
	const onBottomSheet = type === DialogType.BottomSheet
	const { t } = useLabels()
	const { isTouchable } = useResponsive()
	const hasDraggableControl = onBottomSheet && isTouchable

	return (
		<header
			data-dialog-header
			data-testid={dataTestId}
			className={cx(
				styles.header,
				hasDraggableControl && styles.barPadding,
				!title && styles.empty,
				!!subtitle && styles.hasSubtitle,
				className
			)}
		>
			{onBack && (
				<div className={styles.back}>
					<ButtonIconTransparent className={styles.area} onClick={onBack}>
						<IconPrevLarge
							width={20}
							height={20}
							rotateRtl={true}
							alt={t('accessibility.icon.back.text')}
						/>
					</ButtonIconTransparent>
				</div>
			)}

			<div className={styles.fill}>
				{title && (
					<>
						<h1 className={styles.title}>{title}</h1>
						{subtitle && <h2 className={styles.subtitle}>{subtitle}</h2>}
					</>
				)}
			</div>

			{alternativeAction && (
				<div className={cx(styles.alternative)}>{alternativeAction}</div>
			)}

			{!hasDraggableControl && onMinus && (
				<div className={styles.minus}>
					<ButtonIconTransparent className={styles.area} onClick={onMinus}>
						<IconMinus
							rotateRtl={true}
							alt={t('accessibility.icon.minus.text')}
						/>
					</ButtonIconTransparent>
				</div>
			)}
			{!preventClose && (
				<div
					className={cx(
						hasDraggableControl ? fukkuStyles.srOnly : styles.close
					)}
				>
					<ButtonIconTransparent className={styles.area} onClick={api.close}>
						<IconCloseBig
							width={20}
							height={20}
							alt={t('accessibility.icon.cross.close.text')}
						/>
					</ButtonIconTransparent>
				</div>
			)}
		</header>
	)
}
