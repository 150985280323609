'use client'

import { Dialog, type DialogAPI, apiFactory } from 'fukku/Dialog/Dialog'
import { useClosingAnimation } from 'fukku/Dialog/hooks/useClosingAnimation'
import type { DialogProps } from 'fukku/Dialog/types/props'
import { forwardRef, useImperativeHandle, useMemo, useRef } from 'react'
import { cx } from 'utils/cx'

import { DialogContextProvider, DialogType } from '../hooks/useDialogContext'

import styles from './Modal.module.scss'

export function ModalComponent(
	{
		children,
		title,
		preventClose = false,
		onClose,
		'data-testid': dataTestId,
		className,
		isFullScreen = false,
	}: DialogProps & { readonly isFullScreen?: boolean },
	ref: React.Ref<DialogAPI>
) {
	const dialogRef = useRef<HTMLDialogElement>(null)

	const { opened, closing, setOpened } = useClosingAnimation(dialogRef)

	const api = useMemo(
		() =>
			apiFactory(
				setOpened,
				Boolean(dialogRef.current?.open),
				dialogRef.current ?? null
			),
		[setOpened]
	)

	useImperativeHandle(ref, () => api, [api])

	return (
		<DialogContextProvider
			type={DialogType.SimpleDialog}
			api={api}
			preventClose={preventClose}
		>
			<Dialog
				title={title}
				className={cx(
					styles.modal,
					closing && styles.closing,
					isFullScreen && styles.fullscreen,
					className
				)}
				ref={dialogRef}
				preventClose={preventClose}
				setOpened={setOpened}
				opened={opened}
				onClose={onClose}
				data-testid={dataTestId}
			>
				<div className={styles.inner}>{children}</div>
			</Dialog>
		</DialogContextProvider>
	)
}

export const Modal = forwardRef(ModalComponent)
