import { getContainer } from 'fukku/Dialog/utils/getContainer'
import {
	type Dispatch,
	type RefObject,
	type SetStateAction,
	useCallback,
	useMemo,
	useState,
} from 'react'

export function useClosingAnimation(dialogRef: RefObject<HTMLDialogElement>) {
	const [opened, setOpened] = useState(false)
	const [closing, setClosing] = useState(false)

	const delayedSetOpened: Dispatch<SetStateAction<boolean>> = useCallback(
		(value: boolean | ((prev: boolean) => boolean)) => {
			const isElementOpened = Boolean(dialogRef.current?.open)
			if (isElementOpened) {
				setClosing(true)
				getContainer(dialogRef.current)?.addEventListener(
					'animationend',
					() => {
						setOpened(value)
						setClosing(false)
					},
					{ once: true }
				)
			} else {
				setOpened(value)
			}
		},
		[dialogRef]
	)

	return useMemo(
		() => ({ opened, closing, setOpened: delayedSetOpened }),
		[closing, delayedSetOpened, opened]
	)
}
