import { type ReactNode, createContext, useContext, useMemo } from 'react'

import type { DialogAPI } from '../Dialog'

export enum DialogType {
	SimpleDialog = 0,
	FullScreen = 1,
	BottomSheet = 2,
	SideSheet = 3,
	NonModal = 4,
}

export type DialogContextType = {
	type: DialogType
	api: DialogAPI
	preventClose: boolean
}

export type SideSheetContextType = {
	type: DialogType.SideSheet
	api: DialogAPI
	extraWidth: boolean
	preventClose: boolean
}

const DialogContext = createContext<
	DialogContextType | SideSheetContextType | null
>(null)

export function isSideSheetContext(
	context: DialogContextType | SideSheetContextType | null
): context is SideSheetContextType {
	return !!context && 'extraWidth' in context
}

function hasSideSheetProps(
	props: RegularDialogProviderProps | SideSheetDialogProviderProps
): props is SideSheetDialogProviderProps {
	return !!props && 'extraWidth' in props
}

export const useDialogContext = () => {
	const context = useContext(DialogContext)
	if (!context) {
		throw new Error('useDialogContext must be used within a DialogProvider')
	}
	return context
}

export interface RegularDialogProviderProps {
	children: ReactNode
	api: DialogAPI
	type: DialogType
	preventClose: boolean
}

export interface SideSheetDialogProviderProps
	extends RegularDialogProviderProps {
	children: ReactNode
	api: DialogAPI
	type: DialogType.SideSheet
	extraWidth: boolean
	preventClose: boolean
}

export function DialogContextProvider(
	props: Readonly<RegularDialogProviderProps | SideSheetDialogProviderProps>
) {
	const { children, api, type, preventClose, ...rest } = props
	const value = useMemo(
		() => ({
			type,
			preventClose,
			api,
			...(hasSideSheetProps(props) && {
				extraWidth: (rest as SideSheetDialogProviderProps).extraWidth,
			}),
		}),
		[type, api, preventClose, rest]
	)
	return (
		<DialogContext.Provider value={value}>{children}</DialogContext.Provider>
	)
}
